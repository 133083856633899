<template>
    <div>
        <div class="row data-form">
            <div class="col-12 col-md-12">
                <ValidationObserver ref="importForm">
                    <div class="row">

                        <div class="col-12">
                            <ValidationProvider name="academic_year" rules="required" v-slot="{valid, errors}">
                                <b-form-group :label="$t('academic_year')">
                                    <academic-years-selectbox v-model="form.academic_year" :validate-error="errors[0]"></academic-years-selectbox>
                                </b-form-group>
                            </ValidationProvider>
                        </div>

                        <div class="col-12">
                            <ValidationProvider name="module" rules="required" v-slot="{valid, errors}">
                                <b-form-group :label="$t('module')">
                                    <module-selectbox :academic_year="form.academic_year"
                                                      :valueType="'id'"
                                                      v-model="form.module"
                                                      :validate-error="errors[0]"></module-selectbox>
                                </b-form-group>
                            </ValidationProvider>
                        </div>


                        <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                            <ValidationProvider name="file" rules="required" v-slot="{valid, errors}">
                                <b-form-group :label="$t('excel_file')">
                                    <div class="d-flex custom-file-upload">
                                        <b-form-file
                                            v-model="form.file"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            :placeholder="$t('select_file')"
                                            :drop-placeholder="$t('drop_file')"
                                            ref="fileInput"
                                            multiple></b-form-file>
                                        <b-button variant="outline-secondary" @click="$refs.fileInput.$el.childNodes[0].click();">{{$t('browse')}}</b-button>
                                    </div>
                                    <div class="invalid-feedback d-block" v-if="errors[0]" v-html="errors[0]"></div>
                                </b-form-group>
                            </ValidationProvider>
                        </div>

                        <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                            <b-form-group :label="$t('excel_format')">
                                <div class="alert alert-info">
                                    {{formatExcel}}
                                </div>
                            </b-form-group>
                        </div>
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                            <b-form-group>
                                <b-button variant="primary" @click="sendForm">{{$t('import') || toUpperCase}}</b-button>
                            </b-form-group>
                        </div>
                    </div>
                </ValidationObserver>
            </div>
        </div>
    </div>
</template>
<script>
import {ValidationObserver, ValidationProvider} from "vee-validate";
import AcademicYearsSelectbox from "@/components/interactive-fields/AcademicYearsSelectbox.vue";
import ModuleSelectbox from "@/components/interactive-fields/ModuleSelectbox.vue";


export default {
    components: {
        AcademicYearsSelectbox,
        ModuleSelectbox,

        ValidationProvider,
        ValidationObserver,
    },
    props:{
        importMethod:{
            type:Function
        },
        formatExcel:{
            type:String,
            default:''
        }
    },
    data() {
        return {
            form: {
                file: null,
                module:null,
                academic_year:null,
            }
        }
    },
    metaInfo() {
        return {
            title: this.$t("import")
        }
    },
    methods: {
        async sendForm() {
            const isValid = await this.$refs.importForm.validate();
            this.importMethod(isValid,this.form,this.$refs.importForm)
        }
    },

};
</script>
