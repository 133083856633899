<template>
	<div>
		<multiselect
			:placeholder="$t('select')"
			v-model="selected"
			:options="options"
			:class="validateError != '' ? 'box-border-color':''"
			@input="handleInput($event)"
			label="text"
			track-by="value"
			:multiple="false"
			:select-label="''"
			:selected-label="''"
			:deselect-label="''"
			:searchable="true">
            <span slot="noOptions">
                {{ $t('no_options') }}
            </span>
			<span slot="noResult">
                {{ $t('no_result') }}
            </span>
		</multiselect>
		<span
			class="invalid-feedback-custom"
			v-show="validateError"
			v-html="validateError"
		>
    </span>
	</div>
</template>

<script>
	// Helpers
	import setSelected from "@/helpers/setSelected";
	import handleInput from "@/helpers/handleInput";
	
	// Services
	import PrepClassService from "@/services/PrepClassService";
	
	// Other
	import qs from "qs";
	
	export default {
		props: {
			value: {
				default: null,
			},
			validateError: {
				type: String,
				default: "",
			},
			academic_year: {
				default: null,
			},
			module: {
				default: null,
			},
			level: {
				default: null,
			},
		},
		data() {
			return {
				selected: this.value,
				options: [],
				loading: false
			};
		},
		watch: {
			value: function (newValue) {
				this.selected = setSelected(newValue, this.options);
			},
			academic_year: function () {
				this.getOptions();
			},
			module: function () {
				this.getOptions();
			},
			level: {
				immediate: true,
				handler: function () {
					this.getOptions();
				}
			}
		},
		created() {
			this.getOptions();
		},
		methods: {
			handleInput: handleInput,
			setSelected: setSelected,
			getOptions() {
				this.options = [];
				
				if (this.academic_year && this.module && this.level) {
					const config = {
						params: {
							filter: {
								academic_year: this.academic_year,
								module: this.module,
								level: this.level,
							},
							limit: -1,
						},
						paramsSerializer: (params) => qs.stringify(params, {encode: false}),
					}
					PrepClassService.getAll(config)
					                .then((response) => {
						                const data = response.data.data;
						                data.map((item) => {
							                this.options.push({
								                value: item.id,
								                text: item.class,
							                });
						                });
					                })
					                .then(() => {
						                this.selected = setSelected(this.value, this.options);
					                })
				}
			}
		}
	}
</script>
