import Api from '@/services/Index';

const getAll = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/student/programs', config);
}

const get = async (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/student/programs' + id);
}

const search = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/student/programs/search', config);
}

const calculateGpa = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/student/calculate/gpa', formData);
}

const studentSearch = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/students/search', config);
}

const getClassSuccess = async function(config) {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/student/programs/class-success', config);
}

const getGraduationSuccess = async function(config) {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/student/programs/graduation-success', config);
}

const getInstructorStudents = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/instructor/students',config);
}
const studentLogs = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/student/programs/log',config);
}

export default {
    getAll,
    get,
    search,
    calculateGpa,
    studentSearch,
    getClassSuccess,
    getGraduationSuccess,
    getInstructorStudents,
    studentLogs
}
