import Api from '@/services/Index';

const getAll = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/prep/classes', config);
}

const get = async (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/prep/classes/' + id);
}

const getAllFilter = async (academic_year, module, level) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/prep/classes?filter[academic_year]=' + academic_year + '&filter[module]=' + module + '&filter[level]=' + level);
}

const store = async (data) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    if (data.id) {
        return Api.put('/prep/classes/' + data.id, data);
    }
    return Api.post('/prep/classes', data);
}

const del = async (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.delete('/prep/classes/' + id);
}

export default {
    getAll,
    get,
    getAllFilter,
    store,
    del
}
